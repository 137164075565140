import { css } from "styled-components";

export const mobile = (props) => {
  return css`
    @media only screen and (max-width: 480px) {
      ${props}
      html{
        font-size: 9px;
      }
      /* navbar */
      .sc-fmSAUk.cCPkyX.navbar_container{

      }
      .left_div{
        display: none;
      }
      .right_div{
        font-size: 10px;
      }
      
      /* categories */
      .sc-jTrPJq.jAymmJ{
          flex-direction: column;
      }
      .sc-beqWaB.cuoXiN{
      }
      .sc-beqWaB.cuoXiN img{
          height: 300px;
      }
      .sc-beqWaB.cuoXiN .sc-dmqHEX.eCZlMS{

      }
      /* slider */
      .sc-lnAgIa.hoRVBR.slider_container{
          display: none;
      }

      .navbar_container{
          margin-bottom: 50px;
      }
      /* products */
      .sc-bXCLTC.gWJdrh{
          display: flex;
          align-items: center;
          justify-content: center;
      }
      .sc-fUnMCh.cldrqP.product_container{
          min-width: 130px;
          max-width: 45% !important;
          height: 225px;
      }
      .product_container{
        
      }
      /* services */
      .services{
          height: 20vh;
        }
      .services_div{
          width: 95%;     
      }
      .service_div{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
      }
      .service_font{
          font-size: 2rem;
      }
      .service_header{
          margin-top: 8px;
          font-size: 1.2rem;
      }
      .service_content{
          margin-top: 8px;
          font-size: 1rem;
          width: 100%;
      }
      .desc{
          font-size: 1.4rem;
      }
      /* prooduct size in different pages to make double */
      .sc-kgKVFY.cXeQPa{
          min-width: 130px;
          max-width: 49% !important;
          height: 225px;
      }
      /* men */
      .sc-dQelHR.jHiCyM{
          min-width: 130px;
          max-width: 49% !important;
          height: 225px;
      }
      /* woman */
      .sc-dxroEu.jCYNfQ{
          min-width: 130px;
          max-width: 49% !important;
          height: 225px;
      }
      /* cosmetics */
      .sc-kGTyPW.iOMQag{
          min-width: 130px;
          max-width: 49% !important;
          height: 200px;
      }
      .sc-bBeLUv.hpAloQ.kid_container{
        min-width: 130px;
        max-width: 49% !important;
        height: 200px;
      }
      /* suit */
      .sc-gUrTyy.heTmgR{
          min-width: 130px;
          max-width: 49% !important;
          height: 200px;
      }
      /* sport */
      .sc-ejdXBC.JyeZP{
          min-width: 130px;
          max-width: 49% !important;
          height: 200px;
      }
      /* body care */
      .sc-dVCGSn.jDWyyz{
          min-width: 130px;
          max-width: 49% !important;
          height: 200px;
      }
      /* hair care */
      .sc-gAfzvj.iULXsh{
          min-width: 130px;
          max-width: 49% !important;
          height: 200px;
      }
      /* dyfuzor */
      .sc-kVmAmP.dDOuji{
          min-width: 130px;
          max-width: 49% !important;
          height: 200px;
      }
      /* in product */
      .jJagXI{
          flex-direction: column;
      }
      .wrapper{
          display: flex;
          flex-direction: column;
      }      
    }
  `;
};
