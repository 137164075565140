export const sliderItems = [
    {
      id: 1,
      img: "../files/main/main1.png",
      title: "NOWY KOLEKCJA!",
      desc: "MODELE NA CIEPLE DNI NOWA KOLEKCJA JUZ JEST",
      bg: "fdffcd",
    },
    {
      id: 2,
      img: "../files/main/suit_3.png", //https://i.ibb.co/DG69bQ4/2.png
      title: "WSZYSTKO CZEGO POTRZEBUJESZ",
      desc: "NIE IDZIE NA KOMPROMIS W STYLU! WYNAJMIJ MIESZKANIE DLA NOWYCH PRZYJAZDÓW.",
      bg: "e0ffcd",
    },
    {
      id: 3,
      img: "https://i.ibb.co/cXFnLLV/3.png",
      title: "DLA CIEBIE KOCHANIE",
      desc: "MAMY NAJLEPSZE OPCJE NAJLEPSZYCH",
      bg: "ffcab0",
    },
  ]

  export const categories = [
    {
      id: 1,
      img: "https://images.pexels.com/photos/5886041/pexels-photo-5886041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      title: "WOMAN STYLE",
      path:"/woman"
    },
    {
      id: 2,
      img: "files/main/men_fashion3.jpg",
      title: "MAN STYLE",
      path:"/men"
    },
    {
      id: 3,
      img: "files/main/cosmetic2.jpg",
      title: "COSMETICS",
      path:"/cosmetics"
    },
  ]

  export const popularProducts = [
    {
      id:1,
      img:"../files/men/suit_main.jpg",
      kod:1001,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Lycra",
      title: "Biblos Garnitur"
    },
    {
      id:2,
      img:"../files/men/blue_suit_main.jpg",
      kod:1005,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Burgundia Paski Garnitur"
    },
    {
      id:3,
      img:"../files/men/white_suit_main2.jpg",
      kod:1005,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Różowy Garnitur"
      
    },
    {
      id:4,
      img:"../files/men/bej_suit_main.jpg",
      kod:1001,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Lycra",
      title: "Oscar Kwadrat Szary Garnitur"
    },
    {
      id:5,
      img:"../files/cosmetics/house_smell.jpg",
      kod:3004,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Krem do Rak"
    },
    {
      id:6,
      img:"../files/cosmetics/krem-do-stop.jpg",
      kod:3005,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Krem do Stop"
    },
    {
      id:7,
      img:"../files/cosmetics/massage_oil.jpg",
      kod:3006
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Krem do Twarzy"
    },
    {
      id:8,
      img:"../files/cosmetics/mask.jpg",
      kod:3007
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Mydlo Kostka Kartonik"
    },{
      id:9,
      img:"../files/men/weed_tshirt.png",
      kod:1035
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Bob Marley"
    },
    {
      id:10,
      img:"../files/men/skull_men_tshirt.png",
      kod:1035
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "RK Damska Sportowy Komplet"
    },
    {
      id:11,
      img:"../files/men/skull_db_tshirt.png",
      kod:1035
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "RK Niebieska Koszulka Damska"
    },
    {
      id:12,
      img:"../files/men/cj_tshirt.png",
      kod:1035
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "RK Niebieska Koszulka Damska"
    }
  ]

  export const woman = [
    {
      id:1,
      img:"../files/women/1.png",
      kod:7133,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Lycra",
      title: "RK Biała Koszulka Damska"
    },
    {
      id:2,
      img:"../files/women/2.png",
      kod:7147,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "RK Biała Koszulka Damska"
    },
    {
      id:3,
      img:"../files/women/3.png",
      kod:3071,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "RK Beżowa Sukienka Damska"
      
    },
    {
      id:4,
      img:"../files/women/4.png",
      kod:1062,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Lycra",
      title: "RK Czarno-biała koszulka damska"
    },
    {
      id:5,
      img:"../files/women/5.png",
      kod:1056,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "RK Różowa Koszulka Damska"
    },
    {
      id:6,
      img:"../files/women/6.png",
      kod:1058
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "RK T-shirt Damski w Kolorze Różowego Motyla"
    },
    {
      id:7,
      img:"../files/women/7.png",
      kod:1035
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "RK Fioletowa Koszulka Damska"
    },
    {
      id:8,
      img:"../files/women/8.png",
      kod:1035
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "RK Damska Sportowy Komplet"
    },
    {
      id:9,
      img:"../files/women/9.png",
      kod:1035
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "RK Niebieska Koszulka Damska"
    },{
      id:10,
      img:"../files/women/10.png",
      kod:7074,
      title: "RK Biała Wzorzysta Damska Koszulka"
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
    },
    {
      id:11,
      img:"../files/women/11.png",
      kod:7074,
      title: "RK Beżowa Koszulka Damska"
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
    },
    {
      id:12,
      img:"../files/women/12.png",
      kod:7074,
      title: "RK Czarno Koszulka Damska"
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
    },
    {
      id:13,
      img:"../files/women/13.png",
      kod:7074,
      title: "RK Damska Czarny Biały Komplet"
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
    },
    {
      id:14,
      img:"../files/women/14.png",
      kod:2522,
      title: "RK Damska Czarny Biały Komplet"
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
    },
    {
      id:15,
      img:"../files/women/15.png",
      kod:1005,
      title: "RK Damska Zielony Biały Komplet"
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
    }
  ]

  export const men = [
    {
      id:1,
      img:"../files/men/square_blue.jpg",
      kod:1001,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Lycra",
      title: "Oscar Kwadrat Szary Garnitur",
      category : 'suit'
    },
    {
      id:2,
      img:"../files/men/pink_grey_suit.jpg",
      kod:1005,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Burgundia Paski Garnitur",
      category : 'suit'
    },
    {
      id:3,
      img:"../files/men/pink_suit.jpg",
      kod:1005,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Różowy Garnitur",
      category : 'suit'
      
    },
    {
      id:4,
      img:"../files/men/square_suit.jpg",
      kod:1001,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Lycra",
      title: "Oscar Kwadrat Szary Garnitur",
      category : 'suit'
    },{
      id:5,
      img:"../files/men/calvin_tshirt.jpg",
      kod:4626,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "T-shirt z Nadrukiem Na Ramionach",
      category : 'sport'
      
    },
    {
      id:6,
      img:"../files/men/newyork_tshirt.jpg",
      kod:4534,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "New York Koszulki",
      category : 'sport'
    },
    {
      id:7,
      img:"../files/men/raw_tshirt.jpg",
      kod:1005,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Raw Koszulki",
      category : 'sport'
    },
    {
      id:8,
      img:"../files/men/skull_tshirt.jpg",
      kod:4638
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Czaszka Koszulki",
      category : 'sport'
    },
    {
      id:9,
      img:"../files/men/grey_blue_suit.jpg",
      kod:1005,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Niebieski Garnitur",
      category : 'suit'
    },
    {
      id:10,
      img:"../files/men/bej2_jacket.jpg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Beżowy Garnitur",
      category : 'suit'
    },
    {
      id:11,
      img:"../files/men/bej_jacket.jpg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Burgundia Kwadrat Garnitur",
      category : 'suit'
    },
    {
      id:12,
      img:"../files/men/blue_jacket.jpg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Niebieski Garnitur",
      category : 'suit'
    },
    {
      id:13,
      img:"../files/men/black_jacket.jpg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Niebieski Garnitur",
      category : 'suit'
    },{
      id:14,
      img:"../files/men/darkblue_jacket.jpg",
      kod:1005,
      title: "Biblos Kwadrat Ciemnoniebieski Garnitur"
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      category : 'suit'
    },
    {
      id:15,
      img:"../files/men/whiteblack_tshirt.jpg",
      kod:4533
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Czarna Biala Koszulki",
      category : 'sport'
    },
    {
      id:16,
      img:"../files/men/black_armless.jpg",
      kod:4633
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Sport Koszulki",
      category : 'sport'
    },
    {
      id:17,
      img:"../files/men/black_head_tshirt.jpg",
      kod:4547
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Koszulka z Nadrukiem Koszulki",
      category : 'sport'
    },
    {
      id:18,
      img:"../files/men/bob_marley_trible.jpeg",
      kod:1005,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Koszulka z Nadrukiem Koszulki",
      category : 'sport'
    },
    {
      id:19,
      img:"../files/men/game_over_tshirt.jpeg",
      kod:4638
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Game Over Koszulki",
      category : 'sport'
    },
    {
      id:20,
      img:"../files/men/life_tshirt3.jpeg",
      kod:2020,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Drukowane Koszulki",
      category : 'suit'
    },
    {
      id:21,
      img:"../files/men/skull_db_tshirt.png",
      kod:2021
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Drukowane Koszulki",
      category : 'suit'
    },
    {
      id:22,
      img:"../files/men/skull_men_tshirt.png",
      kod:2022
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Drukowane Koszulki",
      category : 'suit'
    },
    {
      id:23,
      img:"../files/men/new_york_tshirt.jpeg",
      kod:2023
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Drukowane New York Koszulki",
      category : 'suit'
    },
    {
      id:24,
      img:"../files/men/thsirt_set.jpeg",
      kod:2024
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Drukowane Koszulki",
      category : 'suit'
    },{
      id:25,
      img:"../files/men/short_set.jpeg",
      kod:2025,
      title: "Krótki"
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      category : 'suit'
    },
    {
      id:26,
      img:"../files/men/trible_tshirt.jpeg",
      kod:2026
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Drukowane Koszulki",
      category : 'sport'
    },
    {
      id:27,
      img:"../files/men/kot_wear_set.jpeg",
      kod:2027
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Jeansy Biblos",
      category : 'suit'
    },
    {
      id:28,
      img:"../files/men/men_hoodie.jpeg",
      kod:2028
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Bluza z kapturem",
      category : 'sport'
    },
    {
      id:29,
      img:"../files/men/blue_grey_square_suit.jpeg",
      kod:2029
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Niebieski Szary Kwadratowy Garnitur",
      category : 'suit'
    },
    {
      id:30,
      img:"../files/men/grey_square_suit.jpeg",
      kod:2030
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Szary Kwadratowy Garnitur",
      category : 'suit'
    },
    {
      id:31,
      img:"../files/men/grey_suit.jpeg",
      kod:2031
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Szary Kwadratowy Garnitur",
      category : 'suit'
    },
    {
      id:32,
      img:"../files/men/red_suit2.jpeg",
      kod:2032
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Czerwony Garnitur",
      category : 'suit'
    }
  ]
  export const suit = [
    {
      id:1,
      img:"../files/men/square_blue.jpg",
      kod:1001,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Lycra",
      title: "Oscar Kwadrat Szary Garnitur",
      category : 'suit'
    },
    {
      id:2,
      img:"../files/men/pink_grey_suit.jpg",
      kod:1005,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Burgundia Paski Garnitur",
      category : 'suit'
    },
    {
      id:3,
      img:"../files/men/pink_suit.jpg",
      kod:1005,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Różowy Garnitur",
      category : 'suit'
      
    },
    {
      id:4,
      img:"../files/men/square_suit.jpg",
      kod:1001,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Lycra",
      title: "Oscar Kwadrat Szary Garnitur",
      category : 'suit'
    },
    {
      id:9,
      img:"../files/men/grey_blue_suit.jpg",
      kod:1005,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Niebieski Garnitur",
      category : 'suit'
    },
    {
      id:10,
      img:"../files/men/bej2_jacket.jpg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Beżowy Garnitur",
      category : 'suit'
    },
    {
      id:11,
      img:"../files/men/bej_jacket.jpg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Burgundia Kwadrat Garnitur",
      category : 'suit'
    },
    {
      id:12,
      img:"../files/men/blue_jacket.jpg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Niebieski Garnitur",
      category : 'suit'
    },
    {
      id:13,
      img:"../files/men/black_jacket.jpg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Niebieski Garnitur",
      category : 'suit'
    },{
      id:14,
      img:"../files/men/darkblue_jacket.jpg",
      kod:1005,
      title: "Biblos Kwadrat Ciemnoniebieski Garnitur"
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      category : 'suit'
    },
    {
      id:29,
      img:"../files/men/blue_grey_square_suit.jpeg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Beżowy Garnitur",
      category : 'suit'
    },
    {
      id:30,
      img:"../files/men/grey_square_suit.jpeg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Burgundia Kwadrat Garnitur",
      category : 'suit'
    },
    {
      id:31,
      img:"../files/men/grey_suit.jpeg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Niebieski Garnitur",
      category : 'suit'
    },
    {
      id:32,
      img:"../files/men/red_suit2.jpeg",
      kod:1005
      ,
      sklad1:"%64 Polyester",
      sklad2:"%34 Viscose",
      sklad3:"%2 Spandex",
      title: "Biblos Kwadrat Niebieski Garnitur",
      category : 'suit'
    },
    {
      id:27,
      img:"../files/men/kot_wear_set.jpeg",
      kod:4633
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Sport Koszulki",
      category : 'suit'
    }
  ]
  export const sport_men = [
    {
      id:5,
      img:"../files/men/calvin_tshirt.jpg",
      kod:4626,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "T-shirt z Nadrukiem Na Ramionach",
      category : 'sport'
      
    },
    {
      id:6,
      img:"../files/men/newyork_tshirt.jpg",
      kod:4534,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "New York Koszulki",
      category : 'sport'
    },
    {
      id:7,
      img:"../files/men/raw_tshirt.jpg",
      kod:1005,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Raw Koszulki",
      category : 'sport'
    },
    {
      id:8,
      img:"../files/men/skull_tshirt.jpg",
      kod:4638
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Czaszka Koszulki",
      category : 'sport'
    },
    {
      id:15,
      img:"../files/men/whiteblack_tshirt.jpg",
      kod:4533
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Czarna Biala Koszulki",
      category : 'sport'
    },
    {
      id:16,
      img:"../files/men/black_armless.jpg",
      kod:4633
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Sport Koszulki",
      category : 'sport'
    },
    {
      id:17,
      img:"../files/men/black_head_tshirt.jpg",
      kod:4547
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Koszulka z Nadrukiem Koszulki",
      category : 'sport'
    },
    {
      id:28,
      img:"../files/men/men_hoodie.jpeg",
      kod:4547
      ,
      sklad1:"%95 Bawelna-cotton",
      sklad2:"%5 Elastan",
      title: "Koszulka z Nadrukiem Koszulki",
      category : 'sport'
    }
  ]
  export const cosmetics = [
    {
      id:1,
      img:"../files/cosmetics/balsam-do-ciala-arbuzowy.jpg",
      kod:3001,
      sklad1:"AQUA, HELIANTHUS ANNUUS SEED OIL, STEARIC ACID, CETEARYL OLIVATE, SORBITAN OLIVATE, GLYCERIN, DEHYDROACETIC ACID, BENZYL ALCOHOL, PARFUM*, TOCOPHEROL",
      sklad2:"",
      sklad3:"",
      title: "Balsam do Ciala Arbuzowy 300ml"
    },
    {
      id:2,
      img:"../files/cosmetics/balsam-do-ciala.jpg",
      kod:3002,
      sklad1:"Hemp Oil, Sunflower Oil, Shea Butter, Olivem 1000 Emulsifier, Vitamin E, Water, Preservative 1%",
      sklad2:"",
      sklad3:"",
      title: "Naturalny balsam do ciała i rąk z olejkiem konopnym 150ml"
    },
    {
      id:3,
      img:"../files/cosmetics/balsam-do-ust.jpg",
      kod:3003,
      sklad1:"Cocoa Butter, Hemp Oil, Almond Oil, Blueberry Wax,",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Balsam do Ust 30ml"
      
    },
    {
      id:4,
      img:"../files/cosmetics/krem-do-rak.jpg",
      kod:3004,
      sklad1:"Hemp Oil, Sunflower Oil, Unrefined Shea Butter, Bepanthenol, Olivem 1000 Emulsifier, Vitamin E, Water, Preservative 1%",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Krem do Rak z Olejem Konopnym 75ml"
    },
    {
      id:5,
      img:"../files/cosmetics/krem-do-stop.jpg",
      kod:3005,
      sklad1:"Hemp Oil, Shea Butter, Urea, Emulsifier, Vitamin E, Preservative",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Krem do Stop 100ml"
    },
    {
      id:6,
      img:"../files/cosmetics/krem-do-twarzy.jpg",
      kod:3006
      ,
      sklad1:"Hemp oil, Sunflower oil, Sea buckthorn oil, Chamomile hydrolate, Pharmaceutical glycerin, Preservative, Sucrose stearate, Emulsifier.",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Krem do Twarzy 50ml"
    },
    {
      id:7,
      img:"../files/cosmetics/mydlo-kostka-kartonik.jpg",
      kod:3007
      ,
      sklad1:"Hemp Oil, Coconut Oil, Rapeseed Oil, Shea Butter, Sodium Hydroxide, Water, Lactic Acid.",
      sklad2:"",
      sklad3:"",
      title: "Naturalne Mydlo Kostce 100g"
    },
    {
      id:8,
      img:"../files/cosmetics/mydlo-pylnnie.jpg",
      kod:3008
      ,
      sklad1:"Hemp Oil, Olive Oil, Castor Oil, Potassium Hydroxide, Water, Lactic Acid, Ethyl Alcohol",
      sklad2:"",
      sklad3:"",
      title: "Naturalne Mydlo w Pylnnie 300ml"
    },
    {
      id:9,
      img:"../files/cosmetics/odzywka-nowa-butelka.jpg",
      kod:3009
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Odzywka Nowa Butelka"
    },{
      id:10,
      img:"../files/cosmetics/odzywka.jpg",
      kod:3010,
      title: "Odzywka"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },
    {
      id:11,
      img:"../files/cosmetics/olejek-cbd.jpg",
      kod:3011,
      sklad1:"5% hemp flower extract, Cannabaceae in Cannabis sativa, Cannabaceae hemp seed oil.",
      sklad2:"",
      sklad3:"",
      title: "Olejek 5-10ml"
    },
    {
      id:12,
      img:"../files/cosmetics/serum-do-twarzy.jpg",
      kod:3012,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Serum do Twarzy"
      
    },
    {
      id:13,
      img:"../files/cosmetics/szampon-kostka-kartonik.jpg",
      kod:3013,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Szampon Kostka Kartonik"
    },
    {
      id:14,
      img:"../files/cosmetics/szampon-nowa-butelka-1.jpg",
      kod:3014,
      sklad1:"Decyl Glucoside, Glycerin, Soapwort Decoction, Sci, Hemp Oil, Betaine, Amide s18, Vitamin E, Preservative, Sodium Alginate.",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Szampon Wzmacniający 200ml"
    },
    {
      id:15,
      img:"../files/cosmetics/tonik-lawenda.jpg",
      kod:3015
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Tonik Lawenda"
    },
    {
      id:16,
      img:"../files/cosmetics/tonik-melisa.jpg",
      kod:3016
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Tonik Melisa"
    },
    {
      id:17,
      img:"../files/cosmetics/tonik-roza.jpg",
      kod:3017
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Tonik Roza"
    },
    {
      id:18,
      img:"../files/cosmetics/zel-pod-prysnic-arbuzowy.jpg",
      kod:3018
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Zel Pod Prysnic Arbuzowy"
    },{
      id:19,
      img:"../files/cosmetics/zel-pod-prysnic.jpg",
      kod:3019,
      title: "Zel Pod Prysnic"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },
    {
      id:20,
      img:"../files/cosmetics/argan_hair_cream.jpeg",
      kod:3020,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Arganowy Krem ​​do Włosów"
    },
    {
      id:21,
      img:"../files/cosmetics/ginseng_hair_cream.jpeg",
      kod:3021,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "żeń-szeń Krem ​​do Włosów"
    },
    {
      id:22,
      img:"../files/cosmetics/tea_tree_hair_cream.jpeg",
      kod:3022,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "drzewo herbaciane Krem ​​do Włosów"
      
    },
    {
      id:23,
      img:"../files/cosmetics/argan_hair_mask.jpeg",
      kod:3023,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Krem do Rak z Olejem Konopnym 75ml"
    },
    {
      id:24,
      img:"../files/cosmetics/ginseng_hair_mask.jpeg",
      kod:3024,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Arganowa Maska ​​do Włosów"
    },
    {
      id:25,
      img:"../files/cosmetics/keratin_hair_mask.jpeg",
      kod:3025
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Keratin Maska ​​do Włosów"
    },
    {
      id:26,
      img:"../files/cosmetics/tea_tree_hair_mask.jpeg",
      kod:3026
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "drzewo herbaciane Maska ​​do Włosów"
    },
    {
      id:27,
      img:"../files/cosmetics/horse_balsam.jpeg",
      kod:3027
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Balsam z Kasztanowca"
    },
    {
      id:28,
      img:"../files/cosmetics/horse_balsam2.jpeg",
      kod:3028
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Balsam z Kasztanowca"
    },{
      id:29,
      img:"../files/cosmetics/horse_kream2.jpeg",
      kod:3029,
      title: "Balsam z Kasztanowca"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },
    {
      id:30,
      img:"../files/cosmetics/bm_house_smell3.jpeg",
      kod:3030,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "BM Dyfuzor"
    },
    {
      id:31,
      img:"../files/cosmetics/house_smell_trible2.jpeg",
      kod:3031,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "BM Dyfuzor"
      
    },
    {
      id:32,
      img:"../files/cosmetics/ilgaz_house_smell.jpeg",
      kod:3032,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Ilgaz Dyfuzor"
    },
    {
      id:33,
      img:"../files/cosmetics/kil_mask.jpeg",
      kod:3033,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Lacinia Kil Maska"
    },
    {
      id:34,
      img:"../files/cosmetics/lavender_massage_oil.jpeg",
      kod:3034
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Lawendowy Olejek do Masażu"
    },
    {
      id:35,
      img:"../files/cosmetics/orange_massage_oil3.jpeg",
      kod:3035
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Pomarańcza Olejek do Masażu"
    },
    {
      id:36,
      img:"../files/cosmetics/sandalwood_massage_oil.jpeg",
      kod:3036
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Sandałowy Olejek do Masażu"
    },
    {
      id:37,
      img:"../files/cosmetics/paprika_balsam.jpeg",
      kod:3037
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Paprykowy Balsam do Masażu"
    },{
      id:38,
      img:"../files/cosmetics/paper_massage_gel.jpeg",
      kod:3038,
      title: "Paprykowy Balsam do Masażu"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },
    {
      id:39,
      img:"../files/cosmetics/argan_shampoo.jpeg",
      kod:3039,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Szampon Arganowy"
    },
    {
      id:40,
      img:"../files/cosmetics/dog_shampoo.jpeg",
      kod:3040,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Szampon Pies"
    },
    {
      id:41,
      img:"../files/cosmetics/ginseng_shampoo.jpeg",
      kod:3041,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Szampon z żeń-szeniem"
      
    },
    {
      id:42,
      img:"../files/cosmetics/tea_tree_shampoo.jpeg",
      kod:3042,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Szampon z Drzewa Herbacianego"
    },
    {
      id:43,
      img:"../files/cosmetics/apricot_shower_gel.jpeg",
      kod:3043,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Morelowy żel pod Prysznic"
    },
    {
      id:44,
      img:"../files/cosmetics/coffee_shower_gel.jpeg",
      kod:3044
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Kawa żel pod Prysznic"
    },
    {
      id:45,
      img:"../files/cosmetics/jasmine_power_gel.jpeg",
      kod:3045
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Jaśminowy żel Energetyczny"
    },
    {
      id:46,
      img:"../files/cosmetics/lemon_shower_gel.jpeg",
      kod:3046
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Cytrynowy żel pod Prysznic"
    },
    {
      id:47,
      img:"../files/cosmetics/nar_shower_gel.jpeg",
      kod:3047
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "żel pod prysznic z granatem"
    },{
      id:48,
      img:"../files/cosmetics/peach_shower_gel.jpeg",
      kod:3048,
      title: "brzoskwiniowy żel pod prysznic"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },
    {
      id:49,
      img:"../files/cosmetics/strawberry_shower_gel.jpeg",
      kod:3049,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Olejek 5-10ml"
    },
    {
      id:50,
      img:"../files/cosmetics/lacina_soap2.jpeg",
      kod:3050,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Truskawkowy_żel pod prysznic"
      
    },
    {
      id:51,
      img:"../files/cosmetics/lacinia_sun_care.jpeg",
      kod:3051,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Pielęgnacja przeciwsłoneczna Lacinia"
    },
    {
      id:52,
      img:"../files/cosmetics/lacinia_sun_care2.jpeg",
      kod:3052,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Pielęgnacja przeciwsłoneczna Lacinia"
    },
    {
      id:53,
      img:"../files/cosmetics/skin_serum.jpeg",
      kod:3053
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Serum do skóry"
    },
    {
      id:54,
      img:"../files/cosmetics/sun_cream.jpeg",
      kod:3054
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Krem do opalania"
    },
    {
      id:55,
      img:"../files/cosmetics/lacinia_lip_care.jpeg",
      kod:3055
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Lacinia pielęgnacja ust"
    },
    {
      id:56,
      img:"../files/cosmetics/hair_care_shot.jpeg",
      kod:3056
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Lacinia Pielęgnacja włosów"
    },{
      id:57,
      img:"../files/cosmetics/auto_smell_set.jpeg",
      kod:3057,
      title: "Elegance zapach auta"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },{
      id:58,
      img:"../files/cosmetics/express_clean.jpeg",
      kod:3058,
      title: "BM Express Czysty"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    }
  ]
  // 1-6 8-9 11-12 14-19 27-29 33-38 51-55
  // 7 10 13 20-26 39-50 56
  // 30-32 57
  export const bodyCare = [
    {
      id:1,
      img:"../files/cosmetics/balsam-do-ciala-arbuzowy.jpg",
      kod:3001,
      sklad1:"AQUA, HELIANTHUS ANNUUS SEED OIL, STEARIC ACID, CETEARYL OLIVATE, SORBITAN OLIVATE, GLYCERIN, DEHYDROACETIC ACID, BENZYL ALCOHOL, PARFUM*, TOCOPHEROL",
      sklad2:"",
      sklad3:"",
      title: "Balsam do Ciala Arbuzowy 300ml"
    },
    {
      id:2,
      img:"../files/cosmetics/balsam-do-ciala.jpg",
      kod:3002,
      sklad1:"Hemp Oil, Sunflower Oil, Shea Butter, Olivem 1000 Emulsifier, Vitamin E, Water, Preservative 1%",
      sklad2:"",
      sklad3:"",
      title: "Naturalny balsam do ciała i rąk z olejkiem konopnym 150ml"
    },
    {
      id:3,
      img:"../files/cosmetics/balsam-do-ust.jpg",
      kod:3003,
      sklad1:"Cocoa Butter, Hemp Oil, Almond Oil, Blueberry Wax,",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Balsam do Ust 30ml"
      
    },
    {
      id:4,
      img:"../files/cosmetics/krem-do-rak.jpg",
      kod:3004,
      sklad1:"Hemp Oil, Sunflower Oil, Unrefined Shea Butter, Bepanthenol, Olivem 1000 Emulsifier, Vitamin E, Water, Preservative 1%",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Krem do Rak z Olejem Konopnym 75ml"
    },
    {
      id:5,
      img:"../files/cosmetics/krem-do-stop.jpg",
      kod:3005,
      sklad1:"Hemp Oil, Shea Butter, Urea, Emulsifier, Vitamin E, Preservative",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Krem do Stop 100ml"
    },
    {
      id:6,
      img:"../files/cosmetics/krem-do-twarzy.jpg",
      kod:3006
      ,
      sklad1:"Hemp oil, Sunflower oil, Sea buckthorn oil, Chamomile hydrolate, Pharmaceutical glycerin, Preservative, Sucrose stearate, Emulsifier.",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Krem do Twarzy 50ml"
    },{
      id:8,
      img:"../files/cosmetics/mydlo-pylnnie.jpg",
      kod:3008
      ,
      sklad1:"Hemp Oil, Olive Oil, Castor Oil, Potassium Hydroxide, Water, Lactic Acid, Ethyl Alcohol",
      sklad2:"",
      sklad3:"",
      title: "Naturalne Mydlo w Pylnnie 300ml"
    },
    {
      id:9,
      img:"../files/cosmetics/odzywka-nowa-butelka.jpg",
      kod:3009
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Odzywka Nowa Butelka"
    },
    {
      id:11,
      img:"../files/cosmetics/olejek-cbd.jpg",
      kod:3011,
      sklad1:"5% hemp flower extract, Cannabaceae in Cannabis sativa, Cannabaceae hemp seed oil.",
      sklad2:"",
      sklad3:"",
      title: "Olejek 5-10ml"
    },
    {
      id:12,
      img:"../files/cosmetics/serum-do-twarzy.jpg",
      kod:3012,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Serum do Twarzy"
      
    },{
      id:14,
      img:"../files/cosmetics/szampon-nowa-butelka-1.jpg",
      kod:3014,
      sklad1:"Decyl Glucoside, Glycerin, Soapwort Decoction, Sci, Hemp Oil, Betaine, Amide s18, Vitamin E, Preservative, Sodium Alginate.",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Szampon Wzmacniający 200ml"
    },
    {
      id:15,
      img:"../files/cosmetics/tonik-lawenda.jpg",
      kod:3015
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Tonik Lawenda"
    },
    {
      id:16,
      img:"../files/cosmetics/tonik-melisa.jpg",
      kod:3016
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Tonik Melisa"
    },
    {
      id:17,
      img:"../files/cosmetics/tonik-roza.jpg",
      kod:3017
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Tonik Roza"
    },
    {
      id:18,
      img:"../files/cosmetics/zel-pod-prysnic-arbuzowy.jpg",
      kod:3018
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Zel Pod Prysnic Arbuzowy"
    },{
      id:19,
      img:"../files/cosmetics/zel-pod-prysnic.jpg",
      kod:3019,
      title: "Zel Pod Prysnic"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },{
      id:27,
      img:"../files/cosmetics/horse_balsam.jpeg",
      kod:3008
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Balsam z Kasztanowca"
    },
    {
      id:28,
      img:"../files/cosmetics/horse_balsam2.jpeg",
      kod:3009
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Balsam z Kasztanowca"
    },{
      id:29,
      img:"../files/cosmetics/horse_kream2.jpeg",
      kod:3010,
      title: "Balsam z Kasztanowca"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },{
      id:33,
      img:"../files/cosmetics/kil_mask.jpeg",
      kod:3014,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Lacinia Kil Maska"
    },
    {
      id:34,
      img:"../files/cosmetics/lavender_massage_oil.jpeg",
      kod:3015
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Lawendowy Olejek do Masażu"
    },
    {
      id:35,
      img:"../files/cosmetics/orange_massage_oil3.jpeg",
      kod:3016
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Pomarańcza Olejek do Masażu"
    },
    {
      id:36,
      img:"../files/cosmetics/sandalwood_massage_oil.jpeg",
      kod:3017
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Sandałowy Olejek do Masażu"
    },
    {
      id:37,
      img:"../files/cosmetics/paprika_balsam.jpeg",
      kod:3018
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Paprykowy Balsam do Masażu"
    },{
      id:38,
      img:"../files/cosmetics/paper_massage_gel.jpeg",
      kod:3019,
      title: "Paprykowy Balsam do Masażu"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },{
      id:51,
      img:"../files/cosmetics/lacinia_sun_care.jpeg",
      kod:3013,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Pielęgnacja przeciwsłoneczna Lacinia"
    },
    {
      id:52,
      img:"../files/cosmetics/lacinia_sun_care2.jpeg",
      kod:3014,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Pielęgnacja przeciwsłoneczna Lacinia"
    },
    {
      id:53,
      img:"../files/cosmetics/skin_serum.jpeg",
      kod:3015
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Serum do skóry"
    },
    {
      id:54,
      img:"../files/cosmetics/sun_cream.jpeg",
      kod:3016
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Krem do opalania"
    },
    {
      id:55,
      img:"../files/cosmetics/lacinia_lip_care.jpeg",
      kod:3017
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Lacinia pielęgnacja ust"
    }
  ]
  export const hairCare = [
    {
      id:7,
      img:"../files/cosmetics/mydlo-kostka-kartonik.jpg",
      kod:3007
      ,
      sklad1:"Hemp Oil, Coconut Oil, Rapeseed Oil, Shea Butter, Sodium Hydroxide, Water, Lactic Acid.",
      sklad2:"",
      sklad3:"",
      title: "Naturalne Mydlo Kostce 100g"
    },{
      id:10,
      img:"../files/cosmetics/odzywka.jpg",
      kod:3010,
      title: "Odzywka"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },{
      id:13,
      img:"../files/cosmetics/szampon-kostka-kartonik.jpg",
      kod:3013,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Szampon Kostka Kartonik"
    },{
      id:20,
      img:"../files/cosmetics/argan_hair_cream.jpeg",
      kod:3001,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Arganowy Krem ​​do Włosów"
    },
    {
      id:21,
      img:"../files/cosmetics/ginseng_hair_cream.jpeg",
      kod:3002,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "żeń-szeń Krem ​​do Włosów"
    },
    {
      id:22,
      img:"../files/cosmetics/tea_tree_hair_cream.jpeg",
      kod:3003,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "drzewo herbaciane Krem ​​do Włosów"
      
    },
    {
      id:23,
      img:"../files/cosmetics/argan_hair_mask.jpeg",
      kod:3004,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Naturalny Krem do Rak z Olejem Konopnym 75ml"
    },
    {
      id:24,
      img:"../files/cosmetics/ginseng_hair_mask.jpeg",
      kod:3005,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Arganowa Maska ​​do Włosów"
    },
    {
      id:25,
      img:"../files/cosmetics/keratin_hair_mask.jpeg",
      kod:3006
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Keratin Maska ​​do Włosów"
    },
    {
      id:26,
      img:"../files/cosmetics/tea_tree_hair_mask.jpeg",
      kod:3007
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "drzewo herbaciane Maska ​​do Włosów"
    },
    {
      id:39,
      img:"../files/cosmetics/argan_shampoo.jpeg",
      kod:3001,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Szampon Arganowy"
    },
    {
      id:40,
      img:"../files/cosmetics/dog_shampoo.jpeg",
      kod:3002,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Szampon Pies"
    },
    {
      id:41,
      img:"../files/cosmetics/ginseng_shampoo.jpeg",
      kod:3003,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Szampon z żeń-szeniem"
      
    },
    {
      id:42,
      img:"../files/cosmetics/tea_tree_shampoo.jpeg",
      kod:3004,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Szampon z Drzewa Herbacianego"
    },
    {
      id:43,
      img:"../files/cosmetics/apricot_shower_gel.jpeg",
      kod:3005,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Morelowy żel pod Prysznic"
    },
    {
      id:44,
      img:"../files/cosmetics/coffee_shower_gel.jpeg",
      kod:3006
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Kawa żel pod Prysznic"
    },
    {
      id:45,
      img:"../files/cosmetics/jasmine_power_gel.jpeg",
      kod:3007
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Jaśminowy żel Energetyczny"
    },
    {
      id:46,
      img:"../files/cosmetics/lemon_shower_gel.jpeg",
      kod:3008
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Cytrynowy żel pod Prysznic"
    },
    {
      id:47,
      img:"../files/cosmetics/nar_shower_gel.jpeg",
      kod:3009
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "żel pod prysznic z granatem"
    },{
      id:48,
      img:"../files/cosmetics/peach_shower_gel.jpeg",
      kod:3010,
      title: "brzoskwiniowy żel pod prysznic"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },
    {
      id:49,
      img:"../files/cosmetics/strawberry_shower_gel.jpeg",
      kod:3011,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Olejek 5-10ml"
    },
    {
      id:50,
      img:"../files/cosmetics/lacina_soap2.jpeg",
      kod:3012,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Truskawkowy_żel pod prysznic"
      
    },{
      id:56,
      img:"../files/cosmetics/hair_care_shot.jpeg",
      kod:3018
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Lacinia Pielęgnacja włosów"
    }
  ]
  export const dyfuzor = [
    {
      id:57,
      img:"../files/cosmetics/auto_smell_set.jpeg",
      kod:3019,
      title: "Elegance zapach auta"
      ,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
    },
    {
      id:30,
      img:"../files/cosmetics/bm_house_smell3.jpeg",
      kod:3011,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "BM Dyfuzor"
    },
    {
      id:31,
      img:"../files/cosmetics/house_smell_trible2.jpeg",
      kod:3012,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "BM Dyfuzor"
      
    },
    {
      id:32,
      img:"../files/cosmetics/ilgaz_house_smell.jpeg",
      kod:3013,
      sklad1:"-",
      sklad2:"",
      sklad3:"",
      title: "Ilgaz Dyfuzor"
    }
  ]