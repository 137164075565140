import styled from "styled-components";
import { dyfuzor } from "./data";
import DyfuzorComponent from "./DyfuzorComponent";
import '../css/hover.css'
import '../css/main.css'
import '../css/responsive.css'
const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const Products = () => {
  return (
    <Container>
      <div className="category_men">
        <a href="/cosmetics"><button className="category_item_btn">All Products</button></a>
        <a href="/body"><button className="category_item_btn">Body Care</button></a>
        <a href="/hair"><button className="category_item_btn">Hair Care</button></a>
        <a href="/dyfuzor"><button className="category_item_btn">Dyfuzor</button></a>  
      </div>
      {dyfuzor.map((item) => (
        <DyfuzorComponent item={item} key={item.id} />
      ))}
    </Container>
  );
};

export default Products;
