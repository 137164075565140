import styled from "styled-components";
import { hairCare } from "./data";
import HairCareComponent from "./HairCareComponent";
import '../css/hover.css'
const Container = styled.div`
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
`;

const Products = () => {
  return (
    <Container>
      <div className="category_men">
        <a href="/cosmetics"><button className="category_item_btn">All Products</button></a>
        <a href="/body"><button className="category_item_btn">Body Care</button></a>
        <a href="/hair"><button className="category_item_btn">Hair Care</button></a>
        <a href="/dyfuzor"><button className="category_item_btn">Dyfuzor</button></a>  
      </div>
      {hairCare.map((item) => (
        <HairCareComponent item={item} key={item.id} />
      ))}
    </Container>
  );
};

export default Products;
